/* @import url("../fonts/font-faces.css"); */

body {
  background-color: #f5f5f5;
  font-family: 'ebr-regular';
}
h1 {
  padding: 0 0.5rem;
}
h1 a {
  text-decoration: none;
}

/* === table styles === */

.ebr-tb {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
}
.ebr-tb-row td:first-child,
.ebr-tb-row th:first-child {
  padding-left: 1.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.ebr-tb-row td:last-child,
.ebr-tb-row th:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.ebr-tb-row td {
  background-color: #ffffff;
  border: 0;
}
.ebr-tb-row th {
  font-weight: 400;
  background-color: #eaeaea;
  border-bottom: 1px solid #dddddd;
}

/* ==== color model ==== */
body {
  color: #483c32;
  font-weight: 300;
}
a {
  color: #0dcaf0;
}
a:hover {
  color: #0aa2c0;
}
h1 a .title-ens {
  color: #483c32;
}
.error-boundary {
  color: indianred;
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #483c32;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #483c32;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #483c32;
  --bs-table-hover-bg: rgba(13, 202, 242, 0.175);
  width: 100%;
  margin-bottom: 1rem;
  color: #483c32;
  vertical-align: top;
  border-color: #dee2e6;
}
.btn-primary,
.bg-primary {
  color: #fff;
  font-weight: 300;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
  box-shadow: 0 0 0 0.1rem rgb(49 132 253 / 50%);
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}
.btn-plain {
  color: #0dcaf0;
}
.add-names-form {
  background-color: #f5f5f5;
}
.form-control:focus,
.form-select:focus {
  color: #483c32;
  background-color: #fff;
  border-color: #9eeaf9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}
.form-check-input {
  border: 1px solid rgba(72, 60, 50, 0.4);
}
.form-check-input:disabled {
  opacity: 0.35;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}
.form-check-input:checked {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.msg-default {
  background-color: #ffffff;
  color: #666;
}
.msg-warning {
  background-color: #eede01;
  color: #666;
}
.msg-success {
  background-color: #a6e9d5;
  color: #666;
}
.msg-fail {
  background-color: #f1aeb5;
  color: #666;
}
.td-index {
  color: #aaaaaa;
}
.td-name-label {
  font-family: 'ebr-semibold';
}
.td-name-label .dot-eth {
  font-family: 'ebr-regular';
  font-size: 0.88rem;
  color: #999;
  margin-left: 0.1rem;
}
.td-time {
  cursor: default;
}
.td-status {
  cursor: default;
  border-radius: 0.15rem;
  padding: 0.07rem 0.35rem;
  text-transform: uppercase;
  font-size: 0.88rem;
}
.td-reg-step {
  margin-top: 0.15rem;
  padding: 0 0.3rem;
  cursor: default;
  border: 1px solid #0dcaf0;
  border-radius: 0.6rem;
  color: #0dcaf0;
  font-size: 0.75rem;
  height: 1.2rem;
  font-family: 'ebr-number-regular';
}
.status-Unknown {
  background-color: #ffffff;
}
.status-Normal {
  background-color: #cccccc65;
}
.status-Open {
  background-color: #a6e9d565;
}
.status-Grace {
  background-color: #f1aeb565;
}
.status-Premium {
  background-color: #eede0165;
}
.status-Reopen {
  background-color: #a6e9d565;
}
.status-Registering,
.status-Booked {
  background-color: #0dcaf065;
}
.name-age {
  margin-left: 0.15rem;
  font-size: smaller;
  color: #483c3277;
}
.td-level {
  cursor: default;
}
.td-level-0 {
  color: #483c32;
}
.td-level-1 {
  color: indianred;
}
.td-level-2 {
  color: limegreen;
}
.is-my-name-true {
  border-bottom: 1px solid #0dcaf0;
}
.is-wrapped-true {
  padding: 0.07rem 0.35rem;
  border-radius: 0.25rem;
  background-color: #0dcaf035;
}
.grace-ending {
  /* animation: grace-ending 3s infinite linear; */
  background-image: linear-gradient(
    to right,
    #f1aeb565 0%,
    #f1aeb565 35%,
    #eede01cc 100%
  );
}
.premium-ending {
  /* animation: premium-ending 3s infinite linear; */
  background-image: linear-gradient(
    to right,
    #eede0165 0%,
    #eede0165 35%,
    #a6e9d5cc 100%
  );
}
/* @keyframes grace-ending {
  0%{background-color: #f1aeb565;}
  25%{background-color: #eede01cc;}
  50%{background-color: #f1aeb565;}
  75%{background-color: #eede01cc;}
  100%{background-color: #f1aeb565;}
}
@keyframes premium-ending {
  0%{background-color: #eede0165;}
  25%{background-color: #a6e9d565;}
  50%{background-color: #eede0165;}
  75%{background-color: #a6e9d565;}
  100%{background-color: #eede0165;}
} */

.tooltip-inner {
  max-width: 275px;
  padding: 0.375rem 0.75rem;
  background-color: #483c32;
  box-shadow: 0px 0px 7px #999999;
  color: #ffffff;
  font-family: 'ebr-medium';
}
.tooltip-inner p {
  margin: 0 0 0.3rem 0;
  padding: 0 0 0.3rem 0;
  border-bottom: 1px solid #ffffff55;
  color: #ffffff99;
}
.tooltip-inner p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.tooltip-inner p.current-true {
  color: #ffffff;
}
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #483c32;
}
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #483c32;
}
.tooltip-time-text {
  font-family: 'ebr-number-regular';
}

/* === end of color model === */

.main-wrapper {
  margin: 1.2rem auto 7rem;
  max-width: 1000px;
}
.header-left,
.header-right {
  text-align: center;
}
.header-right {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .header-left {
    text-align: left;
  }
  .header-right {
    text-align: right;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.page-tag {
  border-radius: 0.1rem;
  background-color: #aaaaaa;
  color: #ffffff;
  font-size: 0.88rem;
  font-weight: 400;
}
.table-wrapper {
  overflow-x: auto;
}
.table-wrapper table thead {
  font-family: 'ebr-medium';
}
.ebr-tb {
  min-width: 720px;
}
.form-add-names {
  margin: 1rem auto;
}
.btn-add-names {
  margin: 0;
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.th-sortable {
  cursor: pointer;
}
button:disabled {
  cursor: default;
}
/* .th-sortable::after {
  margin-left: 0.375rem;
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
} */
.th-remove .dropdown-toggle::after {
  display: none;
}
.remove-list {
  min-width: 12rem;
  padding: 0.2rem;
  text-align: center;
  font-weight: 300;
  font-size: 0.8rem;
}
.remove-tag {
  display: inline-block;
  margin: 0.15rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.15rem;
  cursor: pointer;
}
.remove-tag:hover {
  text-decoration: underline;
}
.light-gray {
  color: #aaaaaa;
}
.display-expiresTime {
  font-size: 0.2rem;
  color: #cc9900;
}
.display-releaseTime {
  font-size: 0.2rem;
  color: sienna;
}
/* .sort-asc-true::after {
  border-top: 2px solid #999999;
  border-right: 2px solid #999999;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.sort-asc-false::after {
  border-top: 2px solid #999999;
  border-right: 2px solid #999999;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
} */
.ebr-tb-row:hover > td > .td-status {
  box-shadow: 0 0 0 1px rgba(13, 202, 242, 0.275);
}
.td-lookup a {
  display: inline-block;
  border-radius: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.75rem;
  background-color: #0dcaf0;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}
.td-lookup a:hover {
  background-color: #0aa2c0;
  color: white;
}
.btn-plain {
  margin: 0 auto;
  border: 0;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  font-weight: 300;
}
.btn-plain:hover {
  text-decoration: underline;
}
button.btn-plain[disabled] {
  color: #aaaaaa;
  text-decoration: none;
}
.btn-bulk-reg,
.btn-bulk-renew {
  font-weight: 400;
  padding: 0 0.375rem;
  border-radius: 0.2rem;
}
.is-bulk-reg,
.is-bulk-renew {
  font-weight: 300;
  background-color: #0dcaf0;
  color: white;
}
.is-bulk-reg:hover,
.is-bulk-renew:hover {
  text-decoration: none;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
}
.form-placeholder {
  background-color: #e9ecef;
  color: #21252950;
}
.btn-sub {
  font-size: 0.88rem;
}
.network-ropsten {
  color: #eb5a8d;
}
.network-sepolia {
  color: #eb5a8d;
}
.select-lang {
  border: 0;
  background-color: #e5e5e5;
  border-radius: 0.15rem;
  font-size: 0.85rem;
}
.wallet-connect {
  line-height: 1;
  font-size: 0.85rem;
  font-weight: 400;
}
.custom-wallet {
  color: #483c32;
}
.spinner-acting {
  width: 0.85rem;
  height: 0.85rem;
}
.footnotes {
  color: darkgrey;
}
.footnotes a {
  color: darkgrey;
}
.footnotes a:hover {
  color: black;
}
.version-v,
.version-number {
  font-size: 0.75rem;
}
.footnode-right {
  text-align: right;
}

.external-link-icon {
  margin-left: 0.375rem;
  font-size: 0.75rem;
}

.toast-container a {
  color: #333333;
  text-decoration: underline;
}
.toast-container a:hover {
  color: #000000;
}
.input-group > .form-select {
  width: 2rem;
}

.modal-message {
  margin: 0.5rem auto 0.5rem;
  font-size: 0.85rem;
  color: #483c32dd;
}
.modal-message-time {
  font-family: 'ebr-number-light';
  color: #483c3299;
}
.modal-message-icon {
  margin-right: 0.25rem;
  width: 0.8rem;
  height: 0.8rem;
}
.action-succeeded,
.message-succeed {
  color: limegreen;
}
.action-failed,
.message-failed {
  color: crimson;
}
.action-suspend {
  color: orange;
}
.until-time {
  font-family: 'ebr-number-light';
}

/* ==== configuration ==== */
.offcanvas-header .btn-close {
  background-color: #cccccc;
}
.lookup-accordion-body {
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  padding: 0.75rem 1rem;
}
.page-tag-color-wrapper {
  padding: 0.15rem 0.3rem;
  background-color: #ffffff;
}
.page-tag-color {
  border: 0;
  padding: 0;
  width: 2.5rem;
  font-size: 0.75rem;
}
.mb-6 {
  margin-bottom: 4rem;
}
.custom-wallet-switch .form-check-input {
  margin-top: 0;
  vertical-align: middle;
}
.custom-wallet-false .input-group-text,
.custom-wallet-false .form-select,
.custom-wallet-false .form-control {
  color: #999999;
}
.conf-btn-box {
  padding: 0.65rem 1rem;
  border-top: solid 1px gainsboro;
  background-color: whitesmoke;
  display: flex;
  justify-content: flex-end;
  width: 399px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.conf-btn-save {
  margin: 0 1rem;
}

.web3modal-modal-lightbox {
  z-index: 2000 !important;
}

/* ==== reload bootstrap ==== */

@keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
  }
  3% {
    -webkit-transform: rotate(25deg);
  }
  6% {
    -webkit-transform: rotate(0deg);
  }
  9% {
    -webkit-transform: rotate(-25deg);
  }
  12% {
    -webkit-transform: rotate(0deg);
  }
  15% {
    -webkit-transform: rotate(25deg);
  }
  18% {
    -webkit-transform: rotate(0deg);
  }
  21% {
    -webkit-transform: rotate(-25deg);
  }
  24% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.test-bar {
  border-radius: 0.25rem;
  background-color: #eede0165;
  color: crimson;
}
.test-bar strong {
  font-family: 'ebr-semibold';
}
.test-bar-buttons > span {
  cursor: pointer;
}
.test-bar-buttons > span:hover {
  text-decoration: underline;
}
